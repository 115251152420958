<template>
        <v-select
            v-model="hardware"
            :label="$t('HardwareSelector.label')"
            :items="hardwares"
            item-text="label"
            item-value="value"
            filled
            hide-details="auto"
            clearable
            append-icon="mdi-chevron-down"
        >
        </v-select>
</template>

<script>
export default {
    computed: {
        hardware: {
            get() {
                return this.$store.getters.hardware
            },
            set(value) {
                this.$store.dispatch('setHardware', value)
            }
        },
        hardwares() {
            return this.$store.getters.hardwares
        }
        
    }
}
</script>