<template>
    <div>
        <span>{{ $t('DifficultySelector.title') }}</span>
        <DifficultySelector /> 
        <HardwareSelector 
            class="mt-4"
        />
        <VersionSelector 
            class="mt-4"
        />
    </div>
</template>   

<script>
import DifficultySelector from '@/components/DifficultySelector.vue'
import VersionSelector from '@/components/VersionSelector.vue'
import HardwareSelector from '@/components/HardwareSelector.vue';

export default {
    components: {
        DifficultySelector,
        VersionSelector,
        HardwareSelector
    }
}
</script>